.header {
  /* object-fit: cover; */
 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  color: white;
  text-align: center;
  height: 500px;
  padding-top: 150px;
}

.overlay {
  /* position: fixed;
  display: none;
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  z-index: 2;
  cursor: pointer; */
}

a:link {
  color: #2093d1;
  font-weight: bold;  
  text-decoration: none;
}

a:visited {
  color: #2093d1;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
