.footer-color{
background-color: #1a1a1a;
/* background-color: #2B6A94; */
;
color: #ffffff;
height: 400px;
padding-top: 80px;
}

h6{
    font-family: 'Titillium Web', sans-serif;
    font-size: 18px;
    font-weight: 900;
}

.copyrigth{
    background-color: #0D1131;
    color: #FFF;
    height: 80px;
    padding-top: 30px;
    font-size: 12px;
    font-weight: 100;
}