.carousel-control-prev{
    background-color: black;
    height: 60px;
    width: 60px;
    margin-top: 300px;
}

.carousel-control-next{
    background-color: black;
    height: 60px;
    width: 60px;
    margin-top: 300px;
}

.carousel-control-prev-icon{
    width: 12px;
}
.carousel-control-next-icon{
    width: 12px;
}

.d-block{
    height: 800px;
}