.navbar {
    background: none;
    padding-top: 20px;
    
  }

  .navbar-light .navbar-nav .nav-link{
    color: #fff;
    }
    

  .navbar-light .navbar-nav .nav-link:hover{
    color: rgb(101, 237, 255);
    }
    
    

    @media only screen and (max-width: 600px) {
      .navbar {
        color: #FFFFFF;
        background-color: #0B5BE0;
      }
      
    
    }